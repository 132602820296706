let target = document.createElement('div');
target.id = "topprojectsbanner-target";

let props = document.createElement('script');
props.innerHTML = JSON.stringify({site_root: "https://texty.org.ua"})
props.type = "application/json";
props.id = "topprojectsbanner-props";

let style = document.createElement('link');
style.href="https://texty.org.ua/static/TopProjectsBanner.css";
style.rel="stylesheet";

let script = document.createElement('script');
script.src = "https://texty.org.ua/static/TopProjectsBanner.js";

document.body.appendChild(style);
document.body.appendChild(props);
document.body.appendChild(target);
document.body.appendChild(script);

